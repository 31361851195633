<template>
  <vue-final-modal
    class="modal-confirm"
    :clickToClose="false"
    @clickOutside="onCloseModal"
    :overlayTransition="{ mode: 'in-out', duration: 200 }"
    :contentTransition="{ mode: 'in-out', duration: 200 }"
  >
    <div class="scroll">
      <div class="header">
        <button-modal-close @close="onCloseModal" />
      </div>

      <atomic-image class="img" src="/img/confirm.svg" />
      <p class="text">{{ getContent(currentLocaleData, defaultLocaleData, 'title') }}</p>
      <button-base type="primary" size="md" @click="onCloseModal">
        {{ getContent(currentLocaleData, defaultLocaleData, 'button') }}
      </button-base>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';

  import { useApiGuestInstance } from '@skeleton/core/assets/apiGuestInstance';

  import { ModalName } from '@skeleton/consts/modals';
  import type { IModalsContent } from '~/types';

  defineProps<{
    currentLocaleData: Maybe<IModalsContent['profileConfirmed']>;
    defaultLocaleData: Maybe<IModalsContent['profileConfirmed']>;
  }>();

  const { closeModal } = useModalStore();
  const { getContent } = useProjectMethods();

  const route = useRoute();

  const confirmCode = ref('');

  onBeforeMount(() => {
    confirmCode.value = route.query.confirmCode as string;
  });

  onMounted(async () => {
    if (!confirmCode.value) return;
    await confirmMail();
  });

  const onCloseModal = (): void => {
    closeModal(ModalName.CONFIRM);
  };

  const confirmMail = async (): Promise<void> => {
    try {
      await useApiGuestInstance('/api/player/register/confirm', {
        method: 'POST',
        body: { code: confirmCode.value },
      });
    } catch (error: any) {
      if (error.response?.status === 422) {
        onCloseModal();
      }
    }
  };
</script>

<style src="~/assets/styles/components/modal/confirm.scss" lang="scss" />
